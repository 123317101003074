import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  ChakraProvider,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
export default function MobileDrawer({ onClose, isOpen,handleLogout }) {
  return (
    <ChakraProvider>
      <Drawer placement={"left"} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">SwiftRewards</DrawerHeader>
          <DrawerBody>
            <div className="flex-row justify-center align-center">
              {/* <div className="flex  pt-6">
                <h1 className="font-bold text-3xl ">SwiftRewards</h1>
              </div> */}
              <div className="pt-2">
                {/* <div className="flex justify-center items-center">
                  <div className="border w-full mb-6  border-gray-300 "></div>
                </div> */}

                {/* Dashboard Link */}
                <nav className="flex items-center  mb-8">
                  <Link to="/dashboard" className="flex items-center">
                    <div className="mr-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M15.5582 1.6665H13.9748C12.1582 1.6665 11.1998 2.62484 11.1998 4.4415V6.02484C11.1998 7.8415 12.1582 8.79984 13.9748 8.79984H15.5582C17.3748 8.79984 18.3332 7.8415 18.3332 6.02484V4.4415C18.3332 2.62484 17.3748 1.6665 15.5582 1.6665ZM6.03317 11.1915H4.44984C2.62484 11.1915 1.6665 12.1498 1.6665 13.9665V15.5498C1.6665 17.3748 2.62484 18.3332 4.4415 18.3332H6.02484C7.8415 18.3332 8.79984 17.3748 8.79984 15.5582V13.9748C8.80817 12.1498 7.84984 11.1915 6.03317 11.1915ZM5.2415 8.8165C5.71098 8.8165 6.17586 8.72403 6.6096 8.54437C7.04334 8.36471 7.43744 8.10138 7.76941 7.76941C8.10138 7.43744 8.36471 7.04334 8.54437 6.6096C8.72403 6.17586 8.8165 5.71098 8.8165 5.2415C8.8165 4.77203 8.72403 4.30715 8.54437 3.87341C8.36471 3.43967 8.10138 3.04557 7.76941 2.7136C7.43744 2.38163 7.04334 2.1183 6.6096 1.93863C6.17586 1.75897 5.71098 1.6665 5.2415 1.6665C4.29335 1.6665 3.38404 2.04315 2.7136 2.7136C2.04315 3.38404 1.6665 4.29335 1.6665 5.2415C1.6665 6.18965 2.04315 7.09897 2.7136 7.76941C3.38404 8.43985 4.29335 8.8165 5.2415 8.8165ZM14.7582 18.3348C15.7063 18.3348 16.6156 17.9582 17.2861 17.2877C17.9565 16.6173 18.3332 15.708 18.3332 14.7598C18.3332 13.8117 17.9565 12.9024 17.2861 12.2319C16.6156 11.5615 15.7063 11.1848 14.7582 11.1848C13.81 11.1848 12.9007 11.5615 12.2303 12.2319C11.5598 12.9024 11.1832 13.8117 11.1832 14.7598C11.1832 15.708 11.5598 16.6173 12.2303 17.2877C12.9007 17.9582 13.81 18.3348 14.7582 18.3348Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    <p className="font-semibold text-base">Dashboard</p>
                  </Link>
                </nav>

                {/* Customers Link */}
                <nav className="flex items-center  mb-8">
                  <Link to="/customers" className="flex items-center">
                    <div className="mr-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M9.9998 9.99984C11.1049 9.99984 12.1647 9.56085 12.9461 8.77945C13.7275 7.99805 14.1665 6.93824 14.1665 5.83317C14.1665 4.7281 13.7275 3.66829 12.9461 2.88689C12.1647 2.10549 11.1049 1.6665 9.9998 1.6665C8.89474 1.6665 7.83493 2.10549 7.05353 2.88689C6.27213 3.66829 5.83314 4.7281 5.83314 5.83317C5.83314 6.93824 6.27213 7.99805 7.05353 8.77945C7.83493 9.56085 8.89474 9.99984 9.9998 9.99984ZM9.9998 12.0832C5.8248 12.0832 2.4248 14.8832 2.4248 18.3332C2.4248 18.5665 2.60814 18.7498 2.84147 18.7498H17.1581C17.3915 18.7498 17.5748 18.5665 17.5748 18.3332C17.5748 14.8832 14.1748 12.0832 9.9998 12.0832Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    <p className="font-semibold text-base">Customers</p>
                  </Link>
                </nav>
                {/* {logout } */}
                <nav className="flex items-center   mb-8">
                  <Link
                    to="/logout"
                    className="flex items-center"
                    onClick={handleLogout}
                  >
                    <div className="mr-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 16 20"
                        fill="none"
                      >
                        <path
                          d="M11 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H11M15 10L11 6M15 10L11 14M15 10H5"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <p className="font-medium text-slate-700 text-base">
                      Log out
                    </p>
                  </Link>
                </nav>
              </div>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </ChakraProvider>
  );
}
