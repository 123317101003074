import React, { useState } from "react";
import Axios from "axios";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  Radio,
  RadioGroup,
  Select,
  HStack,
  Textarea,
  extendTheme,
  Spinner,
  ChakraProvider,
  CSSReset,
} from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    brand: {
      100: "#E5FCF1", // Custom color for selected radio button
      500: "#319795", // Custom color for radio button label
    },
  },
});
const RedeemPointsForm = ({ isOpen, onClose }) => {
  const [loader, setLoader] = useState(false)
  const [password, setPassword] = useState();
  const [contacts, setContacts] = useState();
  const [amountToBeDeducted, setAmountToBeDeducted] = useState(0);
  const [description, setDescription] = useState();
  const [verificationcode, setVerificationcode] = useState();
  const [isexisting, setIsExisting] = useState();
  const [notregistered, setNotRegistered] = useState();
  const [firstname, setFirstName] = useState();
  const [lastname, setLastName] = useState();
  const [pointsavailable, setPointsAvailable] = useState();

  const [step, setStep] = useState(1);
  const RedeemPoints = async () => {
    try {
      const businessId = localStorage.getItem("userID");
      setLoader(true)
      if (businessId) {
        const response = await Axios.post(
          "https://swiftrewardsbackend.onrender.com/api/redeempoints",
          {
            contacts,
            businessId,
            amountToBeDeducted,
            description,
          }
        );
        const { message } = response.data;
        if (message) {
          onClose()
          setLoader(false)
          alert(message);
        } else {
          setLoader(false)
          console.log("message not found");
        }
      } else {
        setLoader(false)
        console.log("Error in getting the buinessID");
      }
    } catch (error) {
      setLoader(false)
      console.log("Error Redeeming points", error);
    }
  };
  
  const handleNextStep = () => {
    if(step===1){
      if(amountToBeDeducted>pointsavailable||pointsavailable===0){
        alert('points to be redeemed is more than available')
     
      }else {
        // Increase the step only if there are enough points available
        if(contacts){
          setStep((prevStep) => prevStep + 1);
        }else{
          alert("please fill the contacts field and search")
        }
        
      }
      
    }
    else{
    setStep((prevStep) => prevStep + 1);
    }
  };
  const checkregisteredcustomer = async () => {
    try {
      if (contacts) {
        setLoader(true)
        const businessId = await localStorage.getItem("userID");
        const response = await Axios.get(
          "https://swiftrewardsbackend.onrender.com/api/isregisteredcustomer",
          {
            params: {
              contacts,
              businessId,
            },
          }
        );
        const { customer, message,registered_customer } = response.data;
        if (customer) {
          setLoader(false)
          const firstname = customer.first_name;
          const lastname = customer.last_name;
          const points = registered_customer.points_available;
          
          setFirstName(firstname);
          setLastName(lastname);
          setPointsAvailable(points);
          setIsExisting(true);
          setNotRegistered();
        } else {
          setLoader(false)
          setIsExisting(false);
          setNotRegistered(true);
        }
      } else {
        setLoader(false)
        alert("contact field empty");
      }
    } catch (error) {
      setLoader(false)
      alert("error while checking is existing customer", error);
    }
  };
  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };
  return (
    <ChakraProvider theme={theme}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          bg="rgba(0, 0, 0, 0.6)"
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          alignItems="center"
          justify="center"
        />
        <ModalContent
          bg="white"
          p={4}
          maxW="1200px"
          borderRadius="md"
          boxShadow="md"
          position="absolute"
        >
          {step === 1 && (
            <>
              <ModalHeader>STEP 1: CUSTOMER DETAILS</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <div className="flex flex-col">
                  <div className="flex flex-col lg:flex-row ">
                    <FormControl isRequired>
                      <FormLabel>Contacts</FormLabel>
                      <div className="flex flex-col gap-y-2 lg:gap-x-2  lg:flex-row ">
                        <Input
                          type="text"
                          placeholder="Enter contacts"
                          value={contacts}
                          onChange={(e) => setContacts(e.target.value)}
                        />

                        <Button onClick={checkregisteredcustomer}>
                        {loader ? (
                              <div className="flex flex-row gap-x-2 justify-center align-center">
                                <h1 className="text-center">Please wait</h1>
                                <Spinner thickness='4px'
                                  speed='0.65s'
                                  emptyColor='gray.200'
                                  color='blue.500'
                                /></div>
                            ) : (
                              <div>Search for contacts</div>
                            )}
                        </Button>
                      </div>
                    </FormControl>
                  </div>
                  {isexisting && (
                    <>
                      <div className="bg-slate-100 mt-4 flex flex-col rounded-lg lg:items-center h-14 justify-center lg:flex-row ">
                        <div className="pl-5 lg:pl-0">
                          <p className="leading-6">
                            Full name:{firstname} {lastname}
                          </p>
                        </div>
                        <div className="leading-6 pl-5">
                          <p>Points available:{pointsavailable}</p>
                        </div>
                      </div>
                      <FormControl isRequired mt={5}>
                        <FormLabel>Redeem Points</FormLabel>
                        <Input
                          type="number"
                          placeholder="Enter amount of points to redeem"
                          value={amountToBeDeducted}
                          onChange={(e) =>
                            setAmountToBeDeducted(e.target.value)
                          }
                        />
                      </FormControl>

                      <Flex direction="column" flex="1" mt={5}>
                        <FormControl isRequired>
                          <FormLabel>Description</FormLabel>
                          <Textarea
                            type="text"
                            placeholder="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </FormControl>
                      </Flex>
                    </>
                  )}
                  {notregistered && (
                    <div className="bg-red-100 h-10 mt-4 flex justify-center items-center rounded-lg">
                      <p className="text-red-500 font-medium text-xl ">
                        Customer is not registered
                      </p>
                    </div>
                  )}
                </div>
              </ModalBody>
            </>
          )}
          {step === 2 && (
            <>
              <ModalHeader>STEP 2: Verify OTP</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex align="center" justify="space-between">
                  <Flex direction="column" flex="1" pl={4}>
                    <FormControl isRequired>
                      <FormLabel>Verify Code</FormLabel>
                      <Input
                        type="number"
                        placeholder="Enter code"
                        value={verificationcode}
                        onChange={(e) => setVerificationcode(e.target.value)}
                      />
                    </FormControl>
                  </Flex>
                </Flex>
              </ModalBody>
            </>
          )}
          {step === 3 && (
            <>
              <ModalHeader>STEP 3: AUTHORIZE REDEEM</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex align="center" justify="center" direction="column">
                  <FormControl isRequired>
                    <FormLabel>Password</FormLabel>
                    <Input
                      type="password"
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </FormControl>
                </Flex>
              </ModalBody>
            </>
          )}
          <ModalFooter>
            {step > 1 && (
              <Button variant="ghost" mr={3} onClick={handlePrevStep}>
                Back
              </Button>
            )}
            {step < 3 ? (
              <Button colorScheme="teal" onClick={handleNextStep}>
                Next
              </Button>
            ) : (
              <Button colorScheme="green" onClick={RedeemPoints}>
                 {loader ? (
                              <div className="flex flex-row gap-x-2 justify-center align-center">
                                <h1 className="text-center">Please wait</h1>
                                <Spinner thickness='4px'
                                  speed='0.65s'
                                  emptyColor='gray.200'
                                  color='blue.500'
                                /></div>
                            ) : (
                              <div>Finish</div>
                            )}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default RedeemPointsForm;
