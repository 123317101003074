import React, { useState, useEffect } from "react";
import { useDisclosure, Button } from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Navbar from "./Navbar";
import Dashboard from "./DashBoard/Dashboard";
import Customer from "./Customers/Customer";
import { CssBaseline } from "@mui/material"; // Update this line
import WelcomeScreen from "./LoginRegister/WelcomeScreen";
import  MobileDrawer  from "./DashBoard/MobileDrawer";
// Chakra UI theme
const chakraTheme = extendTheme({
  // Your Chakra UI theme configuration here
});

// Material UI theme
const materialTheme = createTheme({
  // Your Material UI theme configuration here
});

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [ismenu,setIsMenu]=useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const handleopenmenu=()=>{
   
  setIsMenu(true)
   
    
  }
  const handleLogout = () => {
    localStorage.removeItem("userID");
    setLoggedIn(false);
  };
  const handleclosemenu=()=>{
    setIsMenu(false)
  }
  useEffect(() => {
    // Check if the user is already logged in (you can use a more sophisticated method here)
    // const token = localStorage.getItem("access_token");
    const userID = localStorage.getItem("userID");
    if ( userID) {
      setLoggedIn(true);
    }
  }, []);

  const handleLoginSuccess = () => {
    setLoggedIn(true);
  };

  return (
    <ChakraProvider theme={chakraTheme}>
      <ThemeProvider theme={materialTheme}>
        <CssBaseline />
        <Router>
          
          
          <div className="flex flex-row">
            {
              ismenu&&(
                <MobileDrawer
                isOpen={ismenu}
                onClose={()=>{setIsMenu(false)}}
                handleLogout={handleLogout}/>
              )
            }
            {loggedIn && (
              // Navbar (Hidden on small devices)
              <div className="hidden lg:flex lg:flex-col lg:fixed lg:h-screen ">
                <Navbar handleLogout={handleLogout} />
              </div>
            )}

            {/* Main Content */}
            <div
              className={
                loggedIn
                  ? "flex flex-col w-full ml-0 lg:ml-60"
                  : "flex flex-col w-full"
              }
            >
              {/* Fixed top border */}
              {loggedIn && (
                // <div className="flex  w-screen pb-10 top-0 left-0 right-0 ">
                <div className="flex flex-row fixed border-b-2 w-screen mb-10 z-30 bg-white  h-20  items-center justify-center">
                  <div className="flex bg-slate-200  rounded-lg lg:hidden ml-4">
                    <div className="flex items-center justify-center">
                    <button onClick={handleopenmenu}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 17h14M5 12h14M5 7h14"
                      />
                    </svg>
                    </button>
                    </div>
                  </div>
                  <div className="flex ml-auto lg:ml-96    mx-10   ">
                    <div className="bg-slate-200 rounded-full items-center justify-center h-10 w-10 flex flex-row ">
                      <h1>WL</h1>
                    </div>
                  </div>
                </div>
              )}

              {/* Dashboard Content */}
              <div className={loggedIn ? "mt-20" : ""}>
                {/* Define your routes here */}
                <Routes>
                  {/* Render WelcomeScreen if not logged in */}
                  {!loggedIn && (
                    <Route
                      path="/"
                      element={
                        <WelcomeScreen onLoginSuccess={handleLoginSuccess} />
                      }
                    />
                  )}

                  {/* Redirect to Dashboard if already logged in */}
                  {loggedIn ? (
                    <>
                      <Route path="/" element={<Navigate to="/dashboard" />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/customers" element={<Customer />} />
                    </>
                  ) : (
                    // Fallback route if the requested route is not found or not logged in
                    <Route path="/*" element={<Navigate to="/" />} />
                  )}
                </Routes>
              </div>
            </div>
            {/* Custom CSS to hide scrollbar indicators */}
            <style>{`
              ::-webkit-scrollbar {
                display: none;
              }
            `}</style>
          </div>
         
        </Router>
      </ThemeProvider>
    </ChakraProvider>
  );
}

export default App;
