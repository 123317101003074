import React from "react";
import RevenueChart from "./RevenueChart";
import Axios from "axios";

import { useState, useEffect } from "react";
const Dashboard = () => {
  const [total_customers, setTotalCustomers] = useState(0);
  const [new_customers_today, setNewCustomersToday] = useState(0);
  const [total_gifted_monthly, setTotalGiftedMonthly] = useState(0);
  const [revenue_today, setRevenueToday] = useState(0);
  const [revenue_weekly, setRevenueWeekly] = useState(0);
  const [revenue_monthly, setRevenueMonthly] = useState(0);
  const [revenue_yearly, setRevenueYearly] = useState(0);

  useEffect(() => {
    const queryData = async () => {
      try {
        const businessId = await localStorage.getItem("userID");
        console.log("business Id", businessId);
        const response = await Axios.get(
          "https://swiftrewardsbackend.onrender.com/api/business_data",
          {
            params: {
              businessId: businessId,
            },
          }
        );
        const {
          business,
          total_customers,
          totalTodayRegisteredCustomers,
          totalrewardhistoryforthemonth,
          revenuethismonth,
          totalRevenue,
          totalrevenuetoday,
          success,
        } = response.data;
        if (success) {
          setTotalCustomers(total_customers);
          setNewCustomersToday(totalTodayRegisteredCustomers);
          setTotalGiftedMonthly(totalrewardhistoryforthemonth);
          setRevenueToday(totalrevenuetoday);
          setRevenueMonthly(revenuethismonth);
          setRevenueYearly(totalRevenue);
        }
        console.log("bbb", businessId);
        // Handle the response data here
      } catch (error) {
        console.log("error while fetching data", error);
      }
    };
    queryData();
  }, []); // Fetch data on component mount

  return (
    <div className=" flex bg-slate-100 h-screen flex-col pt-4 overscroll-auto scroll-smooth   px-4 lg:px-6 ">
      <div>
        <p className="text-3xl font-bold pt-4 border-b pb-4 text-slate-700">
          Dashboard
        </p>
        {/* overview */}
        <div className="">
          <p className=" text-slate-800 font-semibold pt-4">OVERVIEW</p>
        </div>
        {/* detials container */}
        <div className=" mt-4 lg:items-center  flex-col w-full gap-y-5 lg:gap-x-48  lg: flex lg:flex-row">
          {/* cutomers */}
          <div className="">
            <div className="border flex flex-row h-28 w-full bg-white shadow-sm rounded-lg lg:w-72 ">
              <div className="flex-col pl-4">
                <p className="text-xs font-semibold pt-2 text-slate-500">
                  TOTAL CUSTOMERS
                </p>
                <p className="text-3xl font-bold text-slate-700 pt-4">
                  {total_customers}
                </p>
              </div>

              <div className="ml-auto  pr-4 pt-7">
                <div className=" bg-slate-200 h-14 w-14 rounded-full flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M10.5 2.3335C7.44329 2.3335 4.95829 4.8185 4.95829 7.87516C4.95829 10.8735 7.30329 13.3002 10.36 13.4052C10.4533 13.3935 10.5466 13.3935 10.6166 13.4052H10.6983C12.1325 13.3573 13.492 12.7536 14.4891 11.7216C15.4863 10.6896 16.043 9.31021 16.0416 7.87516C16.0416 4.8185 13.5566 2.3335 10.5 2.3335ZM16.4266 16.5073C13.1716 14.3373 7.86329 14.3373 4.58495 16.5073C3.10329 17.499 2.28662 18.8407 2.28662 20.2757C2.28662 21.7107 3.10329 23.0407 4.57329 24.0207C6.20662 25.1173 8.35329 25.6657 10.5 25.6657C12.6466 25.6657 14.7933 25.1173 16.4266 24.0207C17.8966 23.029 18.7133 21.699 18.7133 20.2523C18.7016 18.8173 17.8966 17.4873 16.4266 16.5073ZM23.3216 8.56116C23.5083 10.8245 21.8983 12.8078 19.67 13.0762H19.6116C19.5416 13.0762 19.4716 13.0762 19.4133 13.0995C18.2816 13.1578 17.2433 12.7962 16.4616 12.1312C17.6633 11.0578 18.3516 9.44783 18.2116 7.69783C18.1316 6.78783 17.8225 5.9129 17.3133 5.1545C17.9246 4.85709 18.5991 4.71287 19.2786 4.73429C19.9581 4.7557 20.6222 4.9421 21.2135 5.27741C21.8049 5.61271 22.3058 6.08686 22.6731 6.65895C23.0404 7.23103 23.2629 7.88387 23.3216 8.56116Z"
                      fill="black"
                    />
                    <path
                      d="M25.6527 19.3551C25.5593 20.4868 24.836 21.4668 23.6227 22.1318C22.456 22.7735 20.986 23.0768 19.5277 23.0418C20.3677 22.2835 20.8577 21.3385 20.951 20.3351C21.0677 18.8885 20.3793 17.5001 19.0027 16.3918C18.221 15.7735 17.311 15.2835 16.3193 14.9218C18.8977 14.1751 22.141 14.6768 24.136 16.2868C25.2093 17.1501 25.7577 18.2351 25.6527 19.3551Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          {/* new customers */}
          <div className=" ">
            <div className="border flex flex-row h-28 w-full  bg-white shadow-sm lg:w-72 rounded-md">
              <div className=" flex-col pl-4">
                <p className="text-xs font-semibold pt-2 text-slate-500">
                  NEW CUSTOMERS(TODAY)
                </p>
                <p className="text-3xl font-bold text-slate-700 pt-4">
                  {new_customers_today}
                </p>
              </div>

              <div className="ml-auto  pr-4 pt-7">
                <div className=" bg-slate-200 h-14 w-14 rounded-full flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M14 2.3335C10.9433 2.3335 8.45829 4.8185 8.45829 7.87516C8.45829 10.8735 10.8033 13.3002 13.86 13.4052C13.9533 13.3935 14.0466 13.3935 14.1166 13.4052H14.1983C15.6325 13.3573 16.992 12.7536 17.9891 11.7216C18.9863 10.6896 19.543 9.31021 19.5416 7.87516C19.5416 4.8185 17.0566 2.3335 14 2.3335ZM19.9266 16.5073C16.6716 14.3373 11.3633 14.3373 8.08495 16.5073C6.60329 17.499 5.78662 18.8407 5.78662 20.2757C5.78662 21.7107 6.60329 23.0407 8.07329 24.0207C9.70662 25.1173 11.8533 25.6657 14 25.6657C16.1466 25.6657 18.2933 25.1173 19.9266 24.0207C21.3966 23.029 22.2133 21.699 22.2133 20.2523C22.2016 18.8173 21.3966 17.4873 19.9266 16.5073ZM16.3333 21.1507H14.875V22.609C14.875 23.0873 14.4783 23.484 14 23.484C13.5216 23.484 13.125 23.0873 13.125 22.609V21.1507H11.6666C11.1883 21.1507 10.7916 20.754 10.7916 20.2757C10.7916 19.7973 11.1883 19.4007 11.6666 19.4007H13.125V17.9423C13.125 17.464 13.5216 17.0673 14 17.0673C14.4783 17.0673 14.875 17.464 14.875 17.9423V19.4007H16.3333C16.8116 19.4007 17.2083 19.7973 17.2083 20.2757C17.2083 20.754 16.8116 21.1507 16.3333 21.1507Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          {/* gifted customers */}
          <div className=" ">
            <div className="border flex flex-row h-28 w-full  bg-white shadow-sm rounded-md lg:w-72 ">
              <div className=" flex-col pl-4">
                <p className="text-xs font-semibold pt-2 text-slate-500">
                  GIFTED(MONTH)
                </p>
                <p className="text-3xl font-bold text-slate-700 pt-4">
                  {total_gifted_monthly}
                </p>
              </div>

              <div className="ml-auto  pr-4 pt-7">
                <div className=" bg-slate-200 h-14 w-14 rounded-full flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M23.5759 9.12449L14.5926 14.3278C14.2309 14.5378 13.7759 14.5378 13.4026 14.3278L4.41923 9.12449C3.77756 8.75115 3.61423 7.87615 4.10423 7.32782C4.44256 6.94282 4.82756 6.62782 5.2359 6.40615L11.5592 2.90615C12.9126 2.14782 15.1059 2.14782 16.4592 2.90615L22.7826 6.40615C23.1909 6.62782 23.5759 6.95449 23.9142 7.32782C24.3809 7.87615 24.2176 8.75115 23.5759 9.12449ZM13.3349 16.4955V24.4522C13.3349 25.3388 12.4366 25.9222 11.6432 25.5372C9.2399 24.3588 5.19156 22.1538 5.19156 22.1538C3.76823 21.3488 2.60156 19.3188 2.60156 17.6505V11.6305C2.60156 10.7088 3.5699 10.1255 4.36323 10.5805L12.7516 15.4455C13.1016 15.6672 13.3349 16.0638 13.3349 16.4955ZM14.6649 16.4955V24.4522C14.6649 25.3388 15.5632 25.9222 16.3566 25.5372C18.7599 24.3588 22.8082 22.1538 22.8082 22.1538C24.2316 21.3488 25.3982 19.3188 25.3982 17.6505V11.6305C25.3982 10.7088 24.4299 10.1255 23.6366 10.5805L15.2482 15.4455C14.8982 15.6672 14.6649 16.0638 14.6649 16.4955Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* overview ends here */}
        {/* revenue card starts here */}
        <div>
          <p className=" text-slate-800 font-semibold pt-4">REVENUE</p>
        </div>
        <div className="mt-4 border   bg-white shadow-sm rounded-lg mb-10">
          <div className="pt-4 ">
            <h1 className="font-bold  text-lg pl-4">Analytics</h1>
            <div className="p-4">
              <div className=" p-6 border rounded-xl ">
                <div className="flex flex-col gap-y-6 lg:justify-between  lg:flex lg:flex-row ">
                  <div className="flex flex-col">
                    <p className="text-slate-600">Today</p>
                    <p className="text-3xl font-bold">{revenue_today}</p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-slate-600">Weekly</p>
                    <h1 className="text-3xl font-bold">0</h1>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-slate-600">Monthly</p>
                    <h1 className="text-3xl font-bold">{revenue_monthly}</h1>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-slate-600">Yearly</p>
                    <p className="text-3xl font-bold">{revenue_yearly}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
