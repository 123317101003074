import React, { useState, useEffect } from "react";
import Axios from "axios";

import RevenueChart from "../DashBoard/RevenueChart";
import CustomDataGrid from "./Customers Table/CustomerDataTable";
import { useDisclosure, Button } from "@chakra-ui/react";
import AddCustomerFormModal from "./CustomerForms.js/AddCustomerForm";
import GiftCustomerForm from "./CustomerForms.js/GiftCustomerForm";
import RedeemPointsForm from "./CustomerForms.js/RedeemPointsForm";
import RedeemHistoryDataGrid from "./Customers Table/RedeemHistoryData";
import RewardHistoryDataGrid from "./Customers Table/RewardHistoryData";
const Customer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newadded, setNewadded] = useState(0);
  const [total_customers, setTotalCustomers] = useState(0);
  const [new_customers_today, setNewCustomersToday] = useState(0);
  const [total_gifted_monthly, setTotalGiftedMonthly] = useState(0);
  const [revenue_today, setRevenueToday] = useState(0);
  const [revenue_weekly, setRevenueWeekly] = useState(0);
  const [revenue_monthly, setRevenueMonthly] = useState(0);
  const [revenue_yearly, setRevenueYearly] = useState(0);
  const [todayredeemhistory, setTodayRedeemHistory] = useState(0);
  const [monthlyredeemhistory, setMonthlyRedeemHistory] = useState(0);
  const [yearlyredeemhistory, setYearlyRedeemHistory] = useState(0);
  const [weeklyredeemhistory, setWeeklyRedeemHistory] = useState(0);
  const [todayrewardhistory, setTodayRewardHistory] = useState(0);
  const [monthlyrewardhistory, setMonthlyRewardHistory] = useState(0);
  const [yearlyrewardhistory, setYearlyRewardHistory] = useState(0);
  const [added, setAdded] = useState(false);
  const [redeemed, setRedeemed] = useState(false);
  const [redeemhistory, setRedeemHistory] = useState();
  const [rewardhistory, setRewardHistory] = useState();
  //redeem history data
  const redeemhistorydata = async () => {
    try {
      const businessId = await localStorage.getItem("userID");

      const response = await Axios.get(
        "http://192.168.3.141:5000/api/redeemhistorydata",
        {
          params: { businessId },
        }
      );
      const { history, message } = response.data;
      if (history) {
        console.log(history);
        console.log(redeemhistory);
        setRedeemHistory(history);
        setRedeemed(false);
        console.log("success");
      } else {
        alert("unsuccessful");
      }
    } catch (error) {
      console.log("Error in fetching redemption data", error);
    }
  };
  //redeem history data ends here
  //reward history data starts here
  const rewardhistorydata = async () => {
    try {
      const businessId = await localStorage.getItem("userID");
      if (businessId) {
        const response = await Axios.get(
          "http://192.168.3.141:5000/api/rewardhistorydata",
          { params: { businessId } }
        );
        const { rewardhistorydata, message } = response.data;
        if (rewardhistorydata) {
          setRewardHistory(rewardhistorydata);
          console.log(rewardhistorydata);
        } else {
          alert("data not fetched", message);
          console.log("data not fetched", message);
        }
      } else {
        console.log("No business Id ");
      }
    } catch (error) {
      console.log("Error fetching reward history data", error);
    }
  };
  //reward history data ends here
  //reward history analytics starts here
  const rewardhistoryanalytics = async () => {
    try {
      const businessId = await localStorage.getItem("userID");
      const response = await Axios.get(
        "https://swiftrewardsbackend.onrender.com/api/businessrewardanalytics",
        { params: { businessId } }
      );
      const { todaysdata, monthlydata, yearlydata } = response.data;
      if(response){
        setTodayRewardHistory(todaysdata);
        setMonthlyRewardHistory(monthlydata);
        setYearlyRewardHistory(yearlydata);
      }else{
        setTodayRewardHistory(0);
        setMonthlyRewardHistory(0);
        setYearlyRewardHistory(0);
      }

    } catch (error) {
      console.log("Error while fetching reward history analytics",error)

    }
  };
  //reward history analytics ends here

  // redeem history analytics starts here
  const redeemhistoryanalytics = async () => {
    try {
      const businessId = await localStorage.getItem("userID");
      const response = await Axios.get(
        "http://192.168.3.141:5000/api/businessredeemanalytics",
        { params: { businessId } }
      );
      const { todaysdata, monthlydata, yearlydata } = response.data;
      if(response){
        setTodayRedeemHistory(todaysdata);
        setMonthlyRedeemHistory(monthlydata);
        setYearlyRedeemHistory(yearlydata);
      }else{
        setTodayRedeemHistory(0);
        setMonthlyRedeemHistory(0);
        setYearlyRedeemHistory(0);
      }
    } catch (error) {}
  };
  //redeem history analytics ends here
  const queryData = async () => {
    try {
      const businessId = await localStorage.getItem("userID");
      console.log("business Id", businessId);
      const response = await Axios.get(
        "http://192.168.3.141:5000/api/business_data",
        {
          params: {
            businessId: businessId,
          },
        }
      );
      const {
        business,
        total_customers,
        totalTodayRegisteredCustomers,
        totalrewardhistoryforthemonth,
        revenuethismonth,
        totalRevenue,
        totalrevenuetoday,
        success,
        isadded,
      } = response.data;

      if (success) {
        console.log("ati", added);
        console.log("isadded", isadded);
        setTotalCustomers(total_customers);
        setAdded(false);
        setNewCustomersToday(totalTodayRegisteredCustomers);
        setTotalGiftedMonthly(totalrewardhistoryforthemonth);
        setRevenueToday(totalrevenuetoday);
        setRevenueMonthly(revenuethismonth);
        setRevenueYearly(totalRevenue);
      }
      console.log("bbb", businessId);
      // Handle the response data here
    } catch (error) {
      console.log("error while fetching data", error);
    }
  };
  useEffect(() => {
    queryData();
  }, [added]);
  useEffect(() => {
    rewardhistorydata();
    rewardhistoryanalytics()
  }, []);
  useEffect(() => {
    redeemhistorydata();
    redeemhistoryanalytics()
  }, []);
  // Fetch data on component mount
  // useEffect(() => {

  // if(added){
  //   setTotalCustomers(total_customers)
  //   setAdded(false)
  // }

  // }, [total_customers]); // Fetch data on component mount
  const handleAddCustomer = () => {
    console.log("hellooo");
  };
  const handleGiftCustomerClick = () => {
    setGiftFormOpen(true);
  };
  const [giftFormOpen, setGiftFormOpen] = useState(false);
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [redeemFormOpen, setRedeemFormOpen] = useState(false);
  const handleAddCustomerClick = () => {
    setAddFormOpen(true);
  };
  const handleRedeem = () => {
    setRedeemFormOpen(true);
  };

  return (
    <div className="px-3 flex bg-slate-100 flex-col  overscroll-auto scroll-smooth lg:px-4 ">
      {addFormOpen && (
        <AddCustomerFormModal
          isOpen={addFormOpen}
          onClose={() => {
            setAdded(true); // Assuming setAdded updates some other state related to adding
            setAddFormOpen(false); // Close the modal
          }}
        />
      )}

      {giftFormOpen && ( // Only render the GiftCustomerForm when giftFormOpen is true
        <GiftCustomerForm
          isOpen={giftFormOpen}
          onClose={() => {
            setGiftFormOpen(false);
          }}
        />
      )}
      {redeemFormOpen && ( // Only render the GiftCustomerForm when giftFormOpen is true
        <RedeemPointsForm
          isOpen={redeemFormOpen}
          onClose={() => {
            setRedeemFormOpen(false);
            setRedeemed(true);
          }}
        />
      )}
      <div className="flex-col pt-4 ">
        <div className="pt-4 border-b  pb-4">
          <p className="text-3xl font-bold  text-slate-700">Customers</p>
        </div>

        <div className="flex-col  border-b pb-6">
          <div className="mt-4 font-medium">
            <p>MANAGE CUSTOMERS</p>
          </div>
          <div className=" flex border bg-white shadow-sm rounded-lg items-center gap-y-2 lg:justify-between flex-col  lg:flex lg:flex-row mt-4 lg:p-4  lg:h-24 ">
            <button
              className="flex flex-row items-center justify-center bg-slate-200 w-60 rounded-lg mt-3 lg:mt-0 lg:w-40 h-12 "
              onClick={handleAddCustomerClick}
            >
              <p className=" text-xs font-semibold pr-2">ADD CUSTOMER</p>
              <div className="pr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M10.0002 13C4.99016 13 0.910156 16.36 0.910156 20.5C0.910156 20.78 1.13016 21 1.41016 21H18.5902C18.8702 21 19.0902 20.78 19.0902 20.5C19.0902 16.36 15.0102 13 10.0002 13ZM13.7102 1.66002C13.2445 1.13641 12.673 0.717666 12.0335 0.431495C11.3939 0.145323 10.7008 -0.00174761 10.0002 1.56695e-05C8.60016 1.56695e-05 7.32016 0.570016 6.41016 1.51002C5.54016 2.41002 5.00016 3.65002 5.00016 5.00002C5.00016 5.94002 5.26016 6.82002 5.73016 7.57002C5.98016 8.00002 6.30016 8.39002 6.68016 8.71002C7.55016 9.51002 8.71016 10 10.0002 10C11.8302 10 13.4102 9.02002 14.2802 7.57002C14.5402 7.14002 14.7402 6.66002 14.8502 6.16002C14.9502 5.79002 15.0002 5.40002 15.0002 5.00002C15.0002 3.72002 14.5102 2.55002 13.7102 1.66002ZM11.8702 5.92002H10.9402V6.89002C10.9402 7.13932 10.8411 7.37841 10.6648 7.5547C10.4886 7.73098 10.2495 7.83002 10.0002 7.83002C9.75085 7.83002 9.51176 7.73098 9.33548 7.5547C9.15919 7.37841 9.06016 7.13932 9.06016 6.89002V5.92002H8.13016C7.88085 5.92002 7.64176 5.82098 7.46548 5.6447C7.28919 5.46841 7.19016 5.22932 7.19016 4.98002C7.19016 4.73071 7.28919 4.49162 7.46548 4.31534C7.64176 4.13905 7.88085 4.04002 8.13016 4.04002H9.06016V3.15002C9.06016 2.90071 9.15919 2.66162 9.33548 2.48534C9.51176 2.30905 9.75085 2.21002 10.0002 2.21002C10.2495 2.21002 10.4886 2.30905 10.6648 2.48534C10.8411 2.66162 10.9402 2.90071 10.9402 3.15002V4.04002H11.8702C12.1195 4.04002 12.3586 4.13905 12.5348 4.31534C12.7111 4.49162 12.8102 4.73071 12.8102 4.98002C12.8102 5.22932 12.7111 5.46841 12.5348 5.6447C12.3586 5.82098 12.1195 5.92002 11.8702 5.92002Z"
                    fill="#525252"
                  />
                </svg>
              </div>
            </button>
            <button onClick={handleRedeem}>
              <div className="flex flex-row items-center justify-center w-60 bg-slate-200 rounded-lg lg:w-40  h-12">
                <p className=" text-xs font-semibold pl-3 pr-2">
                  REDEEM POINTS
                </p>
                <div className="pr-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM10.95 17.51C10.66 17.8 10.11 18.08 9.71 18.14L7.25 18.49C7.16 18.5 7.07 18.51 6.98 18.51C6.57 18.51 6.19 18.37 5.92 18.1C5.59 17.77 5.45 17.29 5.53 16.76L5.88 14.3C5.94 13.89 6.21 13.35 6.51 13.06L10.97 8.6C11.1289 9.04794 11.3298 9.47986 11.57 9.89C11.67 10.06 11.78 10.22 11.87 10.34C11.98 10.51 12.11 10.67 12.19 10.76C12.24 10.83 12.28 10.88 12.3 10.9C12.55 11.2 12.84 11.48 13.09 11.69C13.16 11.76 13.2 11.8 13.22 11.81C13.37 11.93 13.52 12.05 13.65 12.14C13.81 12.26 13.97 12.37 14.14 12.46C14.34 12.58 14.56 12.69 14.78 12.8C15.01 12.9 15.22 12.99 15.43 13.06L10.95 17.51ZM17.37 11.09L16.45 12.02C16.4211 12.0487 16.3867 12.0715 16.349 12.0869C16.3112 12.1024 16.2708 12.1102 16.23 12.11C16.2 12.11 16.16 12.11 16.14 12.1C15.1354 11.8088 14.2209 11.2683 13.4813 10.5287C12.7417 9.78914 12.2012 8.87455 11.91 7.87C11.88 7.76 11.91 7.64 11.99 7.57L12.92 6.64C14.44 5.12 15.89 5.15 17.38 6.64C18.14 7.4 18.51 8.13 18.51 8.89C18.5 9.61 18.13 10.33 17.37 11.09Z"
                      fill="#525252"
                    />
                  </svg>
                </div>
              </div>
            </button>
            <button
              className="flex flex-row items-center rounded-lg mb-4 lg:mb-0 justify-center w-60 bg-slate-200 lg:w-40 h-12 "
              onClick={handleGiftCustomerClick}
            >
              <p className=" text-xs font-semibold pr-2">GIFT CUSTOMER</p>
              <div className="pr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M20 12V18C20 20.21 18.21 22 16 22H8C5.79 22 4 20.21 4 18V12C4 11.45 4.45 11 5 11H6.97C7.52 11 7.97 11.45 7.97 12V15.14C7.97104 15.671 8.18177 16.1802 8.55634 16.5566C8.9309 16.933 9.43898 17.1463 9.97 17.15C10.35 17.15 10.73 17.04 11.06 16.82L12.01 16.2L12.89 16.79C13.5 17.2 14.28 17.25 14.93 16.9C15.59 16.55 16 15.88 16 15.13V12C16 11.45 16.45 11 17 11H19C19.55 11 20 11.45 20 12ZM21.5 7V8C21.5 9.1 20.97 10 19.5 10H4.5C2.97 10 2.5 9.1 2.5 8V7C2.5 5.9 2.97 5 4.5 5H19.5C20.97 5 21.5 5.9 21.5 7Z"
                    fill="#525252"
                  />
                  <path
                    d="M11.6389 5.00079H6.11894C5.95452 4.82245 5.86575 4.58724 5.87134 4.34473C5.87694 4.10223 5.97647 3.87136 6.14894 3.70079L7.56894 2.28079C7.74866 2.10309 7.9912 2.00342 8.24395 2.00342C8.49669 2.00342 8.73923 2.10309 8.91894 2.28079L11.6389 5.00079ZM17.8719 5.00079H12.3519L15.0719 2.28079C15.2517 2.10309 15.4942 2.00342 15.7469 2.00342C15.9997 2.00342 16.2422 2.10309 16.4219 2.28079L17.8419 3.70079C18.2019 4.06079 18.2119 4.63079 17.8719 5.00079ZM13.9709 10.9998C14.5209 10.9998 14.9709 11.4498 14.9709 11.9998V15.1298C14.9709 15.9298 14.0809 16.4098 13.4209 15.9598L12.5209 15.3598C12.3577 15.2523 12.1664 15.195 11.9709 15.195C11.7754 15.195 11.5842 15.2523 11.4209 15.3598L10.4809 15.9798C9.82094 16.4198 8.94094 15.9398 8.94094 15.1498V11.9998C8.94094 11.4498 9.39094 10.9998 9.94094 10.9998H13.9709Z"
                    fill="#525252"
                  />
                </svg>
              </div>
            </button>
            {/* <div
              className="flex flex-row items-center justify-center bg-gray-200 w-40 h-12 m-5"
              onClick={handleAddCustomer}
            >
              <p className=" text-xs font-semibold pr-2">DELETE CUSTOMER</p>
              <div className="pr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10.0002 12C4.99016 12 0.910156 15.36 0.910156 19.5C0.910156 19.78 1.13016 20 1.41016 20H18.5902C18.8702 20 19.0902 19.78 19.0902 19.5C19.0902 15.36 15.0102 12 10.0002 12ZM13.7102 1.66002C13.2445 1.13641 12.673 0.717666 12.0335 0.431495C11.3939 0.145323 10.7008 -0.00174761 10.0002 1.56695e-05C8.60016 1.56695e-05 7.32016 0.570016 6.41016 1.51002C5.54016 2.41002 5.00016 3.65002 5.00016 5.00002C5.00016 5.94002 5.26016 6.82002 5.73016 7.57002C5.98016 8.00002 6.30016 8.39002 6.68016 8.71002C7.55016 9.51002 8.71016 10 10.0002 10C11.8302 10 13.4102 9.02002 14.2802 7.57002C14.5402 7.14002 14.7402 6.66002 14.8502 6.16002C14.9502 5.79002 15.0002 5.40002 15.0002 5.00002C15.0002 3.72002 14.5102 2.55002 13.7102 1.66002ZM11.8702 5.92002H8.13016C8.00671 5.92002 7.88448 5.8957 7.77043 5.84846C7.65639 5.80122 7.55276 5.73198 7.46548 5.6447C7.37819 5.55741 7.30895 5.45378 7.26171 5.33974C7.21447 5.22569 7.19016 5.10346 7.19016 4.98002C7.19016 4.85657 7.21447 4.73434 7.26171 4.62029C7.30895 4.50625 7.37819 4.40262 7.46548 4.31534C7.55276 4.22805 7.65639 4.15881 7.77043 4.11157C7.88448 4.06433 8.00671 4.04002 8.13016 4.04002H11.8702C11.9936 4.04002 12.1158 4.06433 12.2299 4.11157C12.3439 4.15881 12.4475 4.22805 12.5348 4.31534C12.6221 4.40262 12.6914 4.50625 12.7386 4.62029C12.7858 4.73434 12.8102 4.85657 12.8102 4.98002C12.8102 5.10346 12.7858 5.22569 12.7386 5.33974C12.6914 5.45378 12.6221 5.55741 12.5348 5.6447C12.4475 5.73198 12.3439 5.80122 12.2299 5.84846C12.1158 5.8957 11.9936 5.92002 11.8702 5.92002Z"
                    fill="#525252"
                  />
                </svg>
              </div>
            </div> */}
          </div>
        </div>
        {/* customer analytics card starts here */}
        <div className="pt-4">
          <p className="font-medium">CUSTOMER ANALYTICS</p>
          <div className="border bg-white shadow-sm p-4 rounded-lg lg:flex lg:flex-col mt-4 gap-x-4 lg:justify-center">
            <p className=" font-bold text-slate-400 text-lg">
              Registered Customers
            </p>
            <div className="flex flex-col pt-2  gap-y-2 justify-between lg:flex-row">
              <div>
                <p className="text-slate-600">
                  Total
                  <span></span>
                </p>
                <h1 className="text-3xl font-bold">{total_customers}</h1>
              </div>
              <div>
                <p className="text-slate-600">
                  Today
                  <span></span>
                </p>
                <h1 className="text-3xl font-bold">0</h1>
              </div>
              <div>
                <p className="text-slate-600">Monthly</p>
                <h1 className="text-3xl font-bold">0</h1>
              </div>
              <div>
                <p className="text-slate-600">Yearly</p>
                <h1 className="text-3xl font-bold">0</h1>
              </div>
            </div>
          </div>
        </div>
        {/* customer analytics ends here */}
        {/* customer reward history starts here */}
        <div className="pt-4">
          <p className="font-medium">REWARD ANALYTICS</p>
          <div className="border bg-white shadow-sm rounded-lg lg:flex lg:flex-col mt-4 lg:justify-between justify-center">
            <p className="pt-2 pl-4 font-bold text-slate-400 text-lg">
              Reward History
            </p>
            <div className="flex  flex-col pt-3 lg:justify-between p-4 lg:flex-row">
              <div>
                <p className="text-slate-600">Today</p>
                <h1 className="text-3xl font-bold">{todayrewardhistory}</h1>
              </div>
              <div>
                <p className="text-slate-600">Weekly</p>
                <h1 className="text-3xl font-bold">0</h1>
              </div>
              <div>
                <p className="text-slate-600">Monthly</p>
                <h1 className="text-3xl font-bold">{monthlyrewardhistory}</h1>
              </div>
              <div>
                <p className="text-slate-600">Yearly</p>
                <h1 className="text-3xl font-bold">{yearlyrewardhistory}</h1>
              </div>
            </div>
          </div>
        </div>
        {/* customer reward history details card ends here */}
        {/* {redeem analytics starts here} */}
        <div className="pt-4">
          <p className="font-medium">REDEEM ANALYTICS</p>
          <div className="border bg-white shadow-sm rounded-lg lg:flex lg:flex-col mt-4 lg:justify-between justify-center">
            <p className="pt-2 pl-4 font-bold text-slate-400 text-lg">
              Redeem History
            </p>
            <div className="flex  flex-col pt-3 lg:justify-between p-4 lg:flex-row">
              <div>
                <p className="text-slate-600">Today</p>
                <h1 className="text-3xl font-bold">{todayredeemhistory}</h1>
              </div>
              <div>
                <p className="text-slate-600">Weekly</p>
                <h1 className="text-3xl font-bold">0</h1>
              </div>
              <div>
                <p className="text-slate-600">Monthly</p>
                <h1 className="text-3xl font-bold">{monthlyredeemhistory}</h1>
              </div>
              <div>
                <p className="text-slate-600">Yearly</p>
                <h1 className="text-3xl font-bold">{yearlyredeemhistory}</h1>
              </div>
            </div>
          </div>
        </div>
        {/* {redeem analytics ends here} */}
        {/* Reward on return Details */}
        <div className="border  mb-10 lg:flex lg:flex-col mt-4 ">
          <div className=" ">
            <div className=" flex lg:gap-x-20 lg:flex-row">
              <div>
                <select>
                  <option value="">Today</option>
                  <option value="">Weekly</option>
                  {/* Add other options for monthly and yearly here */}
                </select>
              </div>
              <div>
                <select>
                  <option value="">Today</option>
                  <option value="">Weekly</option>
                  {/* Add other options for monthly and yearly here */}
                </select>
              </div>
              <div>
                <select>
                  <option value="">Today</option>
                  <option value="">Weekly</option>
                  {/* Add other options for monthly and yearly here */}
                </select>
              </div>
            </div>
          </div>
          {/* 
          <div className="pt-10">
            <RevenueChart />
          </div> */}
        </div>
        {/* end of reward on return details */}
        {/* tables start here */}
        {/* customer data table */}
        <div className="flex flex-col mb-10">
          <CustomDataGrid />
        </div>
        {/* customer datatable ends here */}
        {/* redeem history data starts here */}
        <div className="flex flex-col mb-20">
          <p className="mb-5 mt-5 font-bold">Redeem History Data</p>
          <RedeemHistoryDataGrid redeemhistory={redeemhistory} />
        </div>
        {/* redeem history data ends here */}
        {/* reward history starts here */}
        <div className="flex flex-col mb-20">
          <p className="mb-5 mt-5 font-bold">Reward History Data</p>
          <RewardHistoryDataGrid rewardhistory={rewardhistory} />
        </div>
        {/* reward history ends here */}
      </div>
    </div>
  );
};

export default Customer;
