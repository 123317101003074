import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState } from "react";
import Axios from "axios";
// Define the new column names
const VISIBLE_FIELDS = [
  "FULLNAMES",
  "EMAIL",
  "CONTACTS",
  "DATEOFREWARD",
  "INVESTMENT",
  "REWARDTYPE",
  "POINTSREWARDED",
  "DESCRIPTION",
];

export default function RewardHistoryDataGrid({ rewardhistory }) {
  // Ensure that redeemhistory is an array before using it
  const dummyData = rewardhistory || [];

  // Update the data mapping to match your prop structure
  const mappedData = dummyData.map((item) => ({
    FULLNAMES: item.customerName,
    EMAIL: item.customerEmail,
    INVESTMENT:item.AmountInvested,
    CONTACTS: item.customerContacts,
    DATEOFREWARD: item.date,
    POINTSREWARDED: item.rewardAmount,
    DESCRIPTION: item.reason_for_reward,
    REWARDTYPE: item.rewardType, // Fixed typo
    id: item._id, // You may need to add an ID if it's not in the original data
  }));
 
  const filterColumns = ({ field, columns, currentFilters }) => {
    // remove already filtered fields from the list of columns
    const filteredFields = currentFilters?.map((item) => item.field);
    return columns
      .filter(
        (colDef) =>
          colDef.filterable &&
          (colDef.field === field || !filteredFields.includes(colDef.field))
      )
      .map((column) => column.field);
  };

  const getColumnForNewFilter = ({ currentFilters, columns }) => {
    const filteredFields = currentFilters?.map(({ field }) => field);
    const columnForNewFilter = columns
      .filter(
        (colDef) => colDef.filterable && !filteredFields.includes(colDef.field)
      )
      .find((colDef) => colDef.filterOperators?.length);
    return columnForNewFilter?.field ?? null;
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={mappedData}
        columns={VISIBLE_FIELDS.map((field) => ({
          field,
          headerName: field,
          flex: 1,
          filterable: true,
        }))}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          filterPanel: {
            filterFormProps: {
              filterColumns,
            },
            getColumnForNewFilter,
          },
        }}
      />
    </div>
  );
}
