import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  Radio,
  RadioGroup,
  Select,
  HStack,
  extendTheme,
  Spinner,
  ChakraProvider,
  CSSReset,
} from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    brand: {
      100: "#E5FCF1", // Custom color for selected radio button
      500: "#319795", // Custom color for radio button label
    },
  },
});

const AddCustomerFormModal = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);

  // Separate individual states for each form field
  const [loader, setLoader] = useState(false)
  const [detailsform, setDetailsForm] = useState(false);
  const [addbutton, setAddButton] = useState(false);
  const [registered, setRegistered] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contacts, setContacts] = useState();
  const [preferredCommunication, setPreferredCommunication] = useState("both");
  const [gender, setGender] = useState();
  const [country, setcountry] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [reasonForReward, setReasonForReward] = useState("");
  const [amountInvested, setAmountInvested] = useState("");
  const [rewardType, setRewardType] = useState("loyalty points");
  const [amountToBeRewarded, setAmountToBeRewarded] = useState("");
  const [referralId, setReferralId] = useState("");
  const [password, setPassword] = useState("");
  const [isadded, setIsadded] = useState(false);
  const checkisregisteredcustomer = async (contacts) => {
    try {
      const businessId = await localStorage.getItem("userID");
      if (contacts) {
        setLoader(true)
        const response = await Axios.get(
          "https://swiftrewardsbackend.onrender.com/api/isregisteredcustomer",
          {
            params: { contacts, businessId },
          }
        );
        const { message, customer, success, firstmessage } = response.data;
        if (!firstmessage) {
          alert(message);
        } else {
          alert(firstmessage);
        }
        console.log(message, customer);
        if (success !== true) {
          setLoader(false)
          setRegistered(false);
          setDetailsForm(true);
          if (customer) {
            const firstname = customer.first_name;
            const lastname = customer.last_name;
            const gender = customer.gender;
            const birthdate = customer.birthdate;
            const preferred_method_of_communication = customer.preferred_method_of_communication
            setLastName(lastname);
            setBirthDate(birthdate);
            setGender(gender)
            setPreferredCommunication(preferred_method_of_communication)
            setFirstName(firstname);

          }
        } else {
          setLoader(false)
          setRegistered(true);
          setDetailsForm(false);
        }
      } else {
        alert("please fill in the contacts field");
        console.log("empty");
      }
    } catch (error) {
      setLoader(false)
      alert(error);
      console.log("error while checking is registered customer", error);
    }
  };

  // useEffect(()=>{
  //   checkisregisteredcustomer(contacts)
  // },[contacts])

  const add_customer = async () => {
    try {
      setLoader(true)
      const businessId = localStorage.getItem("userID");

      const firstResponse = await Axios.post(
        "https://swiftrewardsbackend.onrender.com/api/businessaddcustomer",
        
        {
          // Pass the individual states to the request

          first_name: firstName,
          last_name: lastName,
          contacts,
          preferredCommunication,
          gender,
          country,
          birthDate,
          // reasonForReward,
          // amountInvested,
          // rewardType,
          // amountToBeRewarded,
          referralId,
          businessId,
        }
      );

      const { firstmessage, secondmessage, thirdmessage, fourthmessage,success } =
        firstResponse.data;
      if (success) {
        setRegistered();
      
        setLoader(false)
        alert("customer added successfully")
        // alert(firstmessage);
        // alert(secondmessage);
        // alert(thirdmessage);
        // alert(fourthmessage);
      } else {
        setLoader(false)
        alert("customer creation unsuccessful");
      }
      // if (firstSuccess) {
      //   console.log("user added successfully",firstSuccess);
      //   console.log();
      //   const secondResponse = await Axios.post("http://192.168.3.116:5000/api/added_customer", {
      //     reasonForReward,
      //     amountInvested,
      //     rewardType,
      //     amountToBeRewarded,
      //     referralId,
      //     businessId,
      //     contacts
      //   });

      //   const { success: secondSuccess } = secondResponse.data;
      //   console.log(secondSuccess)
      //   if (secondSuccess) {
      //     console.log("was a success")
      //     console.log(businessId)

      //     setIsadded(true)
      //     if(isadded===true){
      //       const thirdResponse = await Axios.get("http://192.168.3.116:5000/api/business_data", {
      //         params: {
      //           businessId: businessId,
      //           isadded: isadded
      //         }

      //       });

      //       const{success:thirdSuccess,message}=thirdResponse.data;
      //     if(thirdSuccess){
      //       console.log("user added successfully kabisaa");
      //       console.log("tirtir");
      //     }else{
      //       console.log("third request was not made")
      //       console.log(message)
      //     }

      //   }else{
      //     console.log("kunashida mahali")
      //   }
      //     console.log("added",isadded)
      //     console.log("yy",businessId)

      //   }

      // }
    } catch (error) {
      // Handle error
      setRegistered();

      setLoader(false)
      alert("Error adding new customer", error);
    }
    onClose();
  };

  const handleNextStep = () => {
    if (step <= 2) {
      if (firstName ||lastName ||contacts!=="") {
        setStep((prevStep) => prevStep + 1);
      } else {
        alert("please fill in all the required fields")
      }
    }else{
      setStep((prevStep) => prevStep + 1);
    }
  }


  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = () => {
    // Handle form submission or data logging here
    const formData = {
      firstName,
      lastName,
      contacts,
      preferredCommunication,
      gender,
      country,
      birthDate,
      // reasonForReward,
      // amountInvested,
      // rewardType,
      // amountToBeRewarded,
      // referralId,
      password,
    };
    console.log(JSON.stringify(formData));
    // Show success screen or close modal
    onClose();
  };

  return (
    <ChakraProvider theme={theme}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          bg="rgba(0, 0, 0, 0.6)"
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          alignItems="center"
          justify="center"
        />
        <ModalContent
          bg="white"
          p={4}
          maxW="1200px"
          borderRadius="md"
          boxShadow="md"
          position="absolute"
        >
          {step === 1 && (
            <>
              <ModalHeader>STEP 1:Enter contacts</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <div className="flex flex-col">
                  <div>
                    <div>
                      <p className="font-semibold text-slate-400">
                        1.Enter contacts to search for user
                      </p>
                    </div>

                    <div className="flex flex-row">
                      <FormControl isRequired>
                        <FormLabel>Contacts(required)</FormLabel>
                        <Flex direction="row">
                          <Input
                            type="number"
                            placeholder="0758756100"
                            value={contacts}
                            onChange={(e) => setContacts(e.target.value)}
                          />
                          <Button
                            colorScheme="blue"
                            ml={2}
                            width={300}
                            onClick={() => checkisregisteredcustomer(contacts)}
                          >
                            {loader ? (
                              <div className="flex flex-row gap-x-2 justify-center align-center">
                                <h1 className="text-center">Please wait</h1>
                                <Spinner thickness='4px'
                                  speed='0.65s'
                                  emptyColor='gray.200'
                                  color='blue.500'
                                /></div>
                            ) : (
                              <div>Enter to continue</div>
                            )}
                          </Button>
                        </Flex>
                      </FormControl>
                    </div>

                    {registered && (
                      <>
                        <div>
                          <p className="font-semibold text-base  mt-2 text-green-500 lg:text-lg ">
                            User is Already Registered,Reward User or Redeem
                            points
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  {detailsform === true && (
                    <div className="flex flex-col lg:flex-row mt-5">
                      <div className="flex-1  flex-col">
                        <FormControl isRequired>
                          <FormLabel>First Name(required)</FormLabel>
                          <Input
                            type="text"
                            placeholder="Enter first name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </FormControl>
                        <FormControl isRequired mt={4}>
                          <FormLabel>Last Name(required)</FormLabel>
                          <Input
                            type="text"
                            placeholder="Enter last name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </FormControl>

                        <FormControl isRequired mt={4}>
                          <FormLabel>
                            Preferred Method of Communication
                          </FormLabel>
                          <RadioGroup
                            defaultValue="both"
                            value={preferredCommunication}
                            onChange={(val) => setPreferredCommunication(val)}
                          >
                            <Flex direction="row" justify={"space-between"}>
                              <Radio value="email" colorScheme="teal">
                                Email
                              </Radio>
                              <Radio value="message" colorScheme="teal">
                                Sms
                              </Radio>
                              <Radio value="both" colorScheme="teal">
                                Both
                              </Radio>
                            </Flex>
                          </RadioGroup>
                        </FormControl>
                      </div>

                      <div className="flex-1  flex-col lg:ml-4 ">
                        <FormControl isRequired>
                          <FormLabel>Gender</FormLabel>
                          <RadioGroup
                            defaultValue="male"
                            value={gender}
                            onChange={(val) => setGender(val)}
                          >
                            <HStack spacing="24px">
                              <Radio
                                value="male"
                                colorScheme="purple"
                                zIndex={10}
                              >
                                Male
                              </Radio>
                              <Radio value="female" colorScheme="purple">
                                Female
                              </Radio>
                            </HStack>
                          </RadioGroup>
                        </FormControl>
                        <FormControl isRequired mt={4}>
                          <FormLabel>country</FormLabel>
                          <Input
                            type="text"
                            placeholder="Enter country"
                            value={country}
                            onChange={(e) => setcountry(e.target.value)}
                          />
                        </FormControl>
                        <FormControl isRequired mt={4}>
                          <FormLabel>Birth Date</FormLabel>
                          <Input
                            type="date"
                            value={birthDate}
                            onChange={(e) => setBirthDate(e.target.value)}
                          />
                        </FormControl>
                      </div>
                    </div>
                  )}
                </div>
              </ModalBody>
            </>
          )}
          {/* {step === 2 && (
            <>
              <ModalHeader>STEP 2: PURCHASE / REWARD</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex align="center" justify="space-between">
                  <div className="flex flex-col">
                  <Flex direction="column" flex="1" pr={4}>
                    <FormControl isRequired>
                      <FormLabel>Reason for Reward</FormLabel>
                      <Select
                        placeholder="Select reason"
                        value={reasonForReward}
                        onChange={(e) => setReasonForReward(e.target.value)}
                      >
                        <option value="event">Event</option>
                        <option value="welcome points">Welcome Points</option>
                        <option value="purchase">Purchase</option>
                        <option value="others">Others</option>
                      </Select>
                    </FormControl>
                    <FormControl isRequired mt={4}>
                      <FormLabel>Amount Invested</FormLabel>
                      <Input
                        type="number"
                        placeholder="Enter amount invested"
                        value={amountInvested}
                        onChange={(e) => setAmountInvested(e.target.value)}
                      />
                    </FormControl>
                    <FormControl isRequired mt={4}>
                      <FormLabel>Reward Type</FormLabel>
                      <RadioGroup
                        defaultValue="loyalty points"
                        value={rewardType}
                        onChange={(val) => setRewardType(val)}
                      >
                        <Flex direction="row">
                          <Radio value="airtime" colorScheme="green">
                            Airtime
                          </Radio>
                          <Radio value="loyalty points" colorScheme="green">
                            Loyalty Points
                          </Radio>
                          <Radio value="ride" colorScheme="green">
                            Ride
                          </Radio>
                        </Flex>
                      </RadioGroup>
                    </FormControl>
                  </Flex>
                  <Flex direction="column">
                    <FormControl isRequired>
                      <FormLabel>Amount to Be Rewarded</FormLabel>
                      <Input
                        type="number"
                        placeholder="Enter amount to be rewarded"
                        value={amountToBeRewarded}
                        onChange={(e) => setAmountToBeRewarded(e.target.value)}
                      />
                    </FormControl>
                    <FormControl mt={4}>
                      <FormLabel>Referral Id</FormLabel>
                      <Input
                        type="text"
                        placeholder="Enter referral Id"
                        value={referralId}
                        onChange={(e) => setReferralId(e.target.value)}
                      />
                    </FormControl>
                  </Flex>
                  </div>
                </Flex>
              </ModalBody>
            </>
          )} */}
          {step === 2 && (
            <>
              <ModalHeader>STEP 3: AUTHORIZE ADD</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex align="center" justify="center" direction="column">
                  <FormControl isRequired>
                    <FormLabel>Password</FormLabel>
                    <Input
                      type="password"
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </FormControl>
                </Flex>
              </ModalBody>
            </>
          )}
          <ModalFooter>
            {step > 1 && (
              <Button colorScheme="gray" mr={3} onClick={handlePrevStep}>
                Back
              </Button>
            )}
            {step < 2 ? (
              registered === false ? (
                <Button colorScheme="teal" onClick={handleNextStep}>
                  Next
                </Button>
              ) : (
                <Button variant="ghost" onClick={onClose}>
                  close
                </Button>
              )
            ) : (
              <Button colorScheme="green" onClick={add_customer}>
                {loader ? (
                  <div className="flex flex-row gap-x-2 justify-center align-center">
                    <h1 className="text-center">Please wait</h1>
                    <Spinner thickness='4px'
                      speed='0.65s'
                      emptyColor='gray.200'
                      color='blue.500'
                    /></div>
                ) : (
                  <div>Finish</div>
                )}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default AddCustomerFormModal;
