import React, { useState } from "react";
import Axios from 'axios'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  extendTheme,
  Spinner,
  ChakraProvider,
  useToast,
} from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    brand: {
      100: "#E5FCF1", // Custom color for selected radio button
      500: "#319795", // Custom color for radio button label
    },
  },
});

const GiftCustomerForm = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);

  // Form data for step 1
  const [contacts, setContacts] = useState("");
  const [email, setEmail] = useState("");
  const [rewardType, setRewardType] = useState("loyalty points");
  const [amountToBeRewarded, setAmountToBeRewarded] = useState("");
  const [amountInvested, setAmountInvested] = useState("");
  const [reason_for_reward, setReasonForReward] = useState("");

  // Form data for step 2
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [detailsform, setDetailsForm] = useState(false);
  const toast = useToast();

  const handleNextStep = () => {
    if (step === 1) {
      if (contacts&&amountToBeRewarded) {
        setStep((prevStep) => prevStep + 1);
      } else {
        alert("Please fill in all the required fields");
      }
    } else if (step === 2) {
      if (password) {
        handleSubmit();
      } else {
        alert("Please enter your password to proceed.");
      }
    }
  };

  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async () => {
    try {
      const businessId = localStorage.getItem("userID");
      setLoader(true)
      const response = await Axios.post(
        "https://swiftrewardsbackend.onrender.com/api/rewardcustomer", 
         {
        businessId,
        contacts,
        rewardType,
        amountToBeRewarded,
        amountInvested,
        password,
        reason_for_reward

      });
      const { success, message, success1 } = response.data;

      if (success===true) {

        // Perform form submission or data logging here

        setLoader(false)

        toast({
          title: 'Reward successful',
          description: `We've successfully rewarded ${amountToBeRewarded} `,
          status: 'success',
          duration: 9000,
          isClosable: true,
        })

        // Reset form data and step
        setContacts("");
        setEmail("");
        setRewardType("loyalty points");
        setAmountToBeRewarded("");
        setPassword("");

        
        setTimeout(function() {
          setStep(1);
          // Your code here
          onClose();
      }, 3000);
      }
      if (!success) {
        setLoader(false)
        alert(message);

        toast({
          title: 'Error rewarding customer.',
          description: `${message}`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
        setTimeout(function() {
          // Your code here
          onClose();
      }, 3000);
       
      }
    } catch (error) {
      setLoader(false)
      console.log("Error while gifting customer", error)
    }
  };
  const checkisregisteredcustomer = async () => {
    try {
      const businessId = await localStorage.getItem("userID");
      if (contacts) {
        setLoader(true)
        const response = await Axios.get(
          "https://swiftrewardsbackend.onrender.com/api/isregisteredcustomer",
          {
            params: { contacts, businessId },
          }
        );
        const { message, customer, success, firstmessage } = response.data;
        // if (!firstmessage) {
        //   alert(message);
        // } else {
        //   alert(firstmessage);
        // }
        console.log(message, customer);
        if (success !== true) {
          setLoader(false)
          setDetailsForm(false);
          toast({
            title: 'Unregistered customer',
            description: "cannot reward unregistered customers",
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
         
        } else {
          setLoader(false)
          setDetailsForm(true);

          
        }
      } else {
        alert("please fill in the contacts field");
        console.log("empty");
      }
    } catch (error) {
      setLoader(false)
      alert(error);
      console.log("error while checking is registered customer", error);
    }
  };
  return (
    <ChakraProvider theme={theme}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          bg="rgba(0, 0, 0, 0.6)"
          position="fixed"

          width="100vw"
          height="100vh"
          alignItems="center"
          justify="center"
        />
        <ModalContent
          bg="white"

          maxW="600px"
          borderRadius="md"
          boxShadow="md"

        >
          {step === 1 && (
            <>
              <ModalHeader>STEP 1: CUSTOMER DETAILS</ModalHeader>
              <ModalCloseButton />
              <ModalBody>



                <div>
                  <FormControl isRequired>
                    <FormLabel>Contacts(required)</FormLabel>
                    <div className="flex flex-col gap-y-4 lg:flex-row gap-x-4">
                      <div className="lg:w-3/4 ">


                        <Input
                          type="text"
                          placeholder="Enter contacts"
                          value={contacts}
                          onChange={(e) => setContacts(e.target.value)}
                        />
                      </div>
                      <div className="flex lg:w-1/2 lg:justify-end">
                        <Button colorScheme="teal" onClick={checkisregisteredcustomer} >
                          {loader ? (
                            <div className="flex flex-row gap-x-2 justify-center align-center">
                              <h1 className="text-center">Please wait</h1>
                              <Spinner thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                              /></div>
                          ) : (
                            <div>Search for contacts</div>
                          )}
                        </Button>
                      </div>
                    </div>
                  </FormControl>
                </div>

                {detailsform===true && (<>
                  <FormControl mt={4}>
                    <FormLabel>Email</FormLabel>
                    <Input
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                  <FormControl isRequired mt={4}>
                    <FormLabel>Reward Type</FormLabel>
                    <Select
                      placeholder="Select reward type"
                      value={rewardType}
                      onChange={(e) => setRewardType(e.target.value)}
                    >
                      <option value="airtime">Airtime</option>
                      <option value="loyalty points">Loyalty Points</option>
                      <option value="purchase">Purchase</option>
                      <option value="others">Others</option>
                    </Select>
                  </FormControl>
                  <FormControl mt={4}>
                    <FormLabel>Amount Invested</FormLabel>
                    <Input
                      type="number"
                      placeholder="Enter amount Invested"
                      value={amountInvested}
                      onChange={(e) => setAmountInvested(e.target.value)}
                    />
                  </FormControl>
                  <FormControl mt={4}>
                    <FormLabel>Amount to Be Rewarded(required)</FormLabel>
                    <Input
                      type="number"
                      placeholder="Enter amount to be rewarded"
                      value={amountToBeRewarded}
                      onChange={(e) => setAmountToBeRewarded(e.target.value)}
                    />
                  </FormControl>
                  <FormControl mt={4}>
                    <FormLabel>Reason for reward</FormLabel>
                    <Input

                      placeholder="Reason for reward?"
                      value={reason_for_reward}
                      onChange={(e) => setReasonForReward(e.target.value)}
                    />
                  </FormControl>
                </>)}
              </ModalBody>
            </>
          )}

          {/* Step 2: Authentication Step */}
          {step === 2 && (
            <>
              <ModalHeader>STEP 2: AUTHENTICATION</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl isRequired>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>
              </ModalBody>
            </>
          )}

          <ModalFooter>
            {step > 1 && (
              <Button variant="ghost" mr={3} onClick={handlePrevStep}>
                Back
              </Button>
            )}
            {step < 2 ? (
              <Button colorScheme="teal" onClick={handleNextStep}>
                Next
              </Button>
            ) : (
              <Button colorScheme="green" onClick={handleNextStep}>
                {loader ? (
                            <div className="flex flex-row gap-x-2 justify-center align-center">
                              <h1 className="text-center">Please wait</h1>
                              <Spinner thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                              /></div>
                          ) : (
                            <div>Finish</div>
                          )}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default GiftCustomerForm;
