import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState } from "react";
import Axios from "axios";
// Define the new column names
const VISIBLE_FIELDS = [
  "FULLNAMES",
  "EMAIL",
  "CONTACTS",
  "DATEOFREDEEMPTION",
  "REDEMPTIONTYPE",
  "POINTSREDEEMED",
  "DESCRIPTION",
];

export default function RedeemHistoryDataGrid({ redeemhistory }) {
  // Ensure that redeemhistory is an array before using it
  const dummyData = redeemhistory || [];

  // Update the data mapping to match your prop structure
  const mappedData = dummyData.map((item) => ({
    FULLNAMES: item.customerName,
    EMAIL: item.customerEmail,
    CONTACTS: item.customerContacts,
    DATEOFREDEEMPTION: item.date,
    POINTSREDEEMED: item.pointsRedeemed,
    DESCRIPTION: item.description,
    REDEMPTIONTYPE: item.redemptionType, // Fixed typo
    id: item._id, // You may need to add an ID if it's not in the original data
  }));
  //   const dummyData = [
  //     {
  //       id: 1,
  //       CUSTOMERID: "CUS001",
  //       FULLNAMES: "John Doe",
  //       LOYALTYTIER: "Gold",
  //       CONTACTS: "(123) 456-7890",
  //       DATEOFREGISTRATION: "2023-07-19",
  //       EMAIL: "john.doe@example.com",
  //       ACCOUNTSTATUS: "Active",
  //     },
  //     {
  //       id: 2,
  //       CUSTOMERID: "CUS002",
  //       FULLNAMES: "Jane Smith",
  //       LOYALTYTIER: "Silver",
  //       CONTACTS: "(987) 654-3210",
  //       DATEOFREGISTRATION: "2023-07-20",
  //       EMAIL: "jane.smith@example.com",
  //       ACCOUNTSTATUS: "Active",
  //     },
  //     {
  //       id: 3,
  //       CUSTOMERID: "CUS003",
  //       FULLNAMES: "Michael Johnson",
  //       LOYALTYTIER: "Bronze",
  //       CONTACTS: "(555) 123-4567",
  //       DATEOFREGISTRATION: "2023-07-21",
  //       EMAIL: "michael.johnson@example.com",
  //       ACCOUNTSTATUS: "Active",
  //     },
  //     {
  //       id: 4,
  //       CUSTOMERID: "CUS004",
  //       FULLNAMES: "Emily Williams",
  //       LOYALTYTIER: "Gold",
  //       CONTACTS: "(444) 222-3333",
  //       DATEOFREGISTRATION: "2023-07-22",
  //       EMAIL: "emily.williams@example.com",
  //       ACCOUNTSTATUS: "Active",
  //     },
  //     {
  //       id: 5,
  //       CUSTOMERID: "CUS005",
  //       FULLNAMES: "Daniel Brown",
  //       LOYALTYTIER: "Silver",
  //       CONTACTS: "(777) 888-9999",
  //       DATEOFREGISTRATION: "2023-07-23",
  //       EMAIL: "daniel.brown@example.com",
  //       ACCOUNTSTATUS: "Active",
  //     },
  //     {
  //       id: 6,
  //       CUSTOMERID: "CUS006",
  //       FULLNAMES: "Sophia Lee",
  //       LOYALTYTIER: "Bronze",
  //       CONTACTS: "(222) 333-4444",
  //       DATEOFREGISTRATION: "2023-07-24",
  //       EMAIL: "sophia.lee@example.com",
  //       ACCOUNTSTATUS: "Active",
  //     },
  //     {
  //       id: 7,
  //       CUSTOMERID: "CUS007",
  //       FULLNAMES: "James Kim",
  //       LOYALTYTIER: "Gold",
  //       CONTACTS: "(999) 888-7777",
  //       DATEOFREGISTRATION: "2023-07-25",
  //       EMAIL: "james.kim@example.com",
  //       ACCOUNTSTATUS: "Active",
  //     },
  //     {
  //       id: 8,
  //       CUSTOMERID: "CUS008",
  //       FULLNAMES: "Olivia Johnson",
  //       LOYALTYTIER: "Silver",
  //       CONTACTS: "(666) 777-8888",
  //       DATEOFREGISTRATION: "2023-07-26",
  //       EMAIL: "olivia.johnson@example.com",
  //       ACCOUNTSTATUS: "Active",
  //     },
  //     {
  //       id: 9,
  //       CUSTOMERID: "CUS009",
  //       FULLNAMES: "William Martinez",
  //       LOYALTYTIER: "Bronze",
  //       CONTACTS: "(111) 222-3333",
  //       DATEOFREGISTRATION: "2023-07-27",
  //       EMAIL: "william.martinez@example.com",
  //       ACCOUNTSTATUS: "Active",
  //     },
  //     {
  //       id: 10,
  //       CUSTOMERID: "CUS010",
  //       FULLNAMES: "Ava Davis",
  //       LOYALTYTIER: "Gold",
  //       CONTACTS: "(444) 555-6666",
  //       DATEOFREGISTRATION: "2023-07-28",
  //       EMAIL: "ava.davis@example.com",
  //       ACCOUNTSTATUS: "Active",
  //     },
  //   ];

  const filterColumns = ({ field, columns, currentFilters }) => {
    // remove already filtered fields from the list of columns
    const filteredFields = currentFilters?.map((item) => item.field);
    return columns
      .filter(
        (colDef) =>
          colDef.filterable &&
          (colDef.field === field || !filteredFields.includes(colDef.field))
      )
      .map((column) => column.field);
  };

  const getColumnForNewFilter = ({ currentFilters, columns }) => {
    const filteredFields = currentFilters?.map(({ field }) => field);
    const columnForNewFilter = columns
      .filter(
        (colDef) => colDef.filterable && !filteredFields.includes(colDef.field)
      )
      .find((colDef) => colDef.filterOperators?.length);
    return columnForNewFilter?.field ?? null;
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={mappedData}
        columns={VISIBLE_FIELDS.map((field) => ({
          field,
          headerName: field,
          flex: 1,
          filterable: true,
        }))}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          filterPanel: {
            filterFormProps: {
              filterColumns,
            },
            getColumnForNewFilter,
          },
        }}
      />
    </div>
  );
}
