import React, { useState } from "react";
import { useCookies } from "react-cookie";
import {
  Box,
  Flex,
  Text,
  Image,
  Button,
  FormControl,
  FormLabel,
  Input,
  ChakraProvider,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import svg from "../assets/images/6543-removebg.png";
import RegisterModal from "./RegisterModalForm";
import Axios from "axios";

const WelcomeScreen = ({ onLoginSuccess }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cookies, setCookie] = useCookies(["access_token"]); // Add any other cookie keys if needed

  // State to store the user's email and password
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false)
  const [error, setError] = useState(false)
  const handleLogin = async (event) => {
    event.preventDefault();
    // Handle login logic here
    try {

      if (email && password) {

        setLoader(true)
        const response = await Axios.post(
          "https://swiftrewardsbackend.onrender.com/api/businesses_login",
          {
            email,
            password,
          }
        );
        const { token, userID, success,message } = response.data;

        if (success) {
          setEmail("");
          setPassword("");
          setCookie("access_token", token); // Set the cookie for access_token
          localStorage.setItem("userID", userID); // Store the userID in local storage
          onLoginSuccess(); // Call the onLogin prop when the login is successful
          setLoader(false)
        } else {
          alert(message)
          setLoader(false)
        }
      } else {

        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000); // Set the duration in milliseconds (e.g., 3000 milliseconds = 3 seconds)
      };
    }
    catch (error) {
      console.log("Error while Logging in", error);
      setLoader(false)
      // alert(error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <ChakraProvider>
      <>
        {error && (
          <Alert status='error'>
            <AlertIcon />
            <AlertTitle>You left a field empty</AlertTitle>
            <AlertDescription>Please fill in all the fields to login</AlertDescription>
          </Alert>)}
        <div>
          <RegisterModal isOpen={isModalOpen} onClose={closeModal} />
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-center h-screen w-screen bg-blue-200">
          <div className="flex flex-col lg:flex-row lg:justify-center w-11/12 h-4/6 lg:h-3/4 lg:w-3/4 bg-white rounded-lg shadow-2xl">
            <div className="flex flex-row">
              <div className="hidden lg:flex lg:flex-col lg:w-3/5 lg:p-6 ">

                <p className="text-3xl font-bold text-blue-500 text-center pt-16 tracking-wide">JOIN US TODAY</p>
                <Image src={svg} alt="" mt="16" />
              </div>

              <div className="flex flex-col w-full h-full p-4 lg:items-stretch lg:justify-center   lg:w-2/5 lg:h-full lg:p-6 ">
                <p className="lg:text-3xl  pt-50 text-3xl text-green-400" >
                  Login in to your account
                </p>
                <p className="font-bold pt-4">Don't have an account?
                  <button


                    onClick={() => setIsModalOpen(true)}
                  >
                    <p className="text-blue-600">Sign Up</p>
                  </button>

                </p>
                {/* Email and Password Forms */}
                <form>
                  <FormControl mt="8">
                    <FormLabel>Email</FormLabel>
                    {/* Update the value of the email input based on user input */}
                    <Input
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                  <FormControl mt="4">
                    <FormLabel>Password</FormLabel>
                    {/* Update the value of the password input based on user input */}
                    <Input
                      type="password"
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </FormControl>
                  {/* Login Button */}
                  <Button
                    colorScheme="blue"
                    size="lg"
                    rounded="xl"
                    fontWeight="bold"
                    width="full"
                    mt="8"
                    type="button" // Use type="button" to prevent form submission
                    onClick={handleLogin}
                  >
                    {loader ? (
                      <div className="flex flex-row gap-x-2"><h1 className="">Please wait</h1>
                        <Spinner thickness='4px'
                          speed='0.65s'
                          emptyColor='gray.200'
                          color='blue.500'
                        /></div>
                    ) : (
                      <div>Login</div>
                    )}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    </ChakraProvider>
  );
};

export default WelcomeScreen;
