// RegisterFormModal.js
import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  InputGroup,
  InputLeftAddon,
  Select,
  Spinner,
  extendTheme,
  ChakraProvider,
} from "@chakra-ui/react";
import Axios from "axios";

const theme = extendTheme({
  colors: {
    brand: {
      100: "#E5FCF1", // Custom color for selected radio button
      500: "#319795", // Custom color for radio button label
    },
  },
});

const RegisterFormModal = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);
  const [businessName, setBusinessName] = useState("");
  const [businessLocation, setBusinessLocation] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [contact, setContact] = useState("");
  const [branchesNo, setBranchesNo] = useState("");
  const [pointValue, setPointValue] = useState("");
  const [category, setCategory] = useState("");
  const [country, setCountry] = useState("");
  const [aboutCompany, setAboutCompany] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setconfirmPassword] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [minimumredeemed, setMinimumRedeemed] = useState();
  const [points_equivalence, setPointsEquivalence] = useState();
  const [error, setError] = useState(false)
  const [loader, setLoader] = useState(false)
  const handleNextStep = async () => {
    if (step === 1) {
      //check if the business exists in the database by contacts
      if (contact, country, businessEmail, businessName) {
        try {
          setLoader(true)
          const response = await Axios.get(
           
            "https://swiftrewardsbackend.onrender.com/api/checkexistingbusinessbycontacts",
            { params: { contacts: contact } }
          );
          const { message, success } = response.data
          if (!success) {
            setStep((prevStep) => prevStep + 1);
            setLoader(false)
          }
          else {
            alert(message)
            setLoader(false)
          }
        } catch (error) {
          setLoader(false)
          console.log(error)
        }
      } else {
        alert("please fill in all the required fields ")
        setStep(1)
      }
      //check if there is any empty field that is required to be filled
      //close the modal automatically if business already exists

    }
    if (step === 2) {
      setStep((prevStep) => prevStep + 1);
    }

  };

  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };
  const handleSubmit = async () => {
    try {
      if (password !== "" && password === confirmpassword) {
        setLoader(true)
        const response = await Axios.post(
          "https://swiftrewardsbackend.onrender.com/api/newbusiness",
          {
            company_name: businessName,
            email: businessEmail,
            contacts: contact,
            branches_no: branchesNo,
            point_value: pointValue,
            category,
            country,
            about_company: aboutCompany,
            password,
            websiteLink,
            minimumredeemed,
            point_equivalence: points_equivalence,
          }
        );
        const { success } = response.data;
        if (success) {
          setLoader(false)
        alert("Business registered successfully!");

          // Reset form fields after successful submission
          setBusinessName("");
          setBusinessEmail("");
          setContact("");
          setBranchesNo("");
          setPointValue("");
          setCategory("");
          setCountry("");
          setAboutCompany("");
          setPassword("");
          setWebsiteLink("");
          setMinimumRedeemed("");
          setPointsEquivalence("");
          setconfirmPassword("");
          setStep(1);
          // Show success screen or close modal
          onClose();
        } else {
          setLoader(false)
          alert("Error during registration");
        }
      } else {
        setLoader(false)
        alert(" password should match");
      }
    } catch (error) {
      setLoader(false)
      console.log("ERROR IN REGISTRATION", error);
      alert("ERROR IN REGISTRATION", error);
    }
  };
  return (
    <ChakraProvider theme={theme}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <div className="flex">
          <ModalContent
            bg="white"
            p={4}
            maxW="1200px"
            borderRadius="md"
            boxShadow="md"
          >
            {step === 1 && (
              <>
                <ModalHeader>STEP 1: BUSINESS DETAILS</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <div className="flex flex-col lg:flex-row gap-y-2">
                    <Flex direction="column" flex={1} pr={4}>
                      <FormControl isRequired>
                        <FormLabel>Business Name(required)</FormLabel>
                        <Input
                          type="text"
                          placeholder="Enter business name"
                          value={businessName}
                          onChange={(e) => setBusinessName(e.target.value)}
                          required
                        />
                      </FormControl>
                      <FormControl isRequired mt={6}>
                        <FormLabel>Business Email(required)</FormLabel>
                        <Input
                          type="email"
                          placeholder="Enter business email"
                          value={businessEmail}
                          onChange={(e) => setBusinessEmail(e.target.value)}
                        />
                      </FormControl>
                      <FormControl isRequired mt={6}>
                        <FormLabel>Country(required)</FormLabel>
                        <Select
                          placeholder="Select Country"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          <option value="Kenya">Kenya</option>
                          <option value="Tanzania">Tanzania</option>
                          <option value="Uganda">Uganda</option>
                          {/* Add more options based on your requirements */}
                        </Select>
                      </FormControl>
                      <FormControl isRequired mt={6}>
                        <FormLabel>Contact(required)</FormLabel>

                        <InputGroup>
                          <InputLeftAddon children="+254" />
                          <Input
                            type="tel"
                            placeholder="758756100"
                            value={contact}
                            onChange={(e) => setContact(e.target.value)}
                          />
                        </InputGroup>
                      </FormControl>
                      <FormControl isRequired mt={6}>
                        <FormLabel>Branches No(Optional)</FormLabel>
                        <Input
                          type="number"
                          placeholder="Enter branches number"
                          value={branchesNo}
                          onChange={(e) => setBranchesNo(e.target.value)}
                        />
                      </FormControl>
                    </Flex>
                    <div className=" flex-1 flex-col">
                      <FormControl isRequired>
                        <FormLabel>Category(Optional)</FormLabel>
                        <Select
                          placeholder="Select category"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option value="manufacturing">Manufacturing</option>
                          <option value="technology">Technology</option>
                          <option value="services">Services</option>
                          {/* Add more options based on your requirements */}
                        </Select>
                      </FormControl>

                      <FormControl isRequired mt={6}>
                        <FormLabel>About Company(Optional)</FormLabel>
                        <Input
                          as="textarea"
                          placeholder="Enter about company"
                          value={aboutCompany}
                          onChange={(e) => setAboutCompany(e.target.value)}
                        />
                      </FormControl>
                      <FormControl isRequired mt={4}>
                        <FormLabel>Website Link(Optional)</FormLabel>
                        <Input
                          as="textarea"
                          placeholder="Paste your website link"
                          value={websiteLink}
                          onChange={(e) => setWebsiteLink(e.target.value)}
                        />
                      </FormControl>
                      <FormControl isRequired mt={6}>
                        <FormLabel>Location(Optional)</FormLabel>
                        <Input
                          type="text"
                          placeholder="Business location"
                          value={businessLocation}
                          onChange={(e) => setBusinessLocation(e.target.value)}
                        />
                      </FormControl>
                    </div>
                  </div>
                </ModalBody>
              </>
            )}
            {step === 3 && (
              <>
                <ModalHeader>
                  STEP 3: SECURITY AND ACCOUNT CONFIRMATION
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Flex align="center" justify="center" direction="column">
                    <FormControl isRequired>
                      <FormLabel>Password</FormLabel>
                      <Input
                        type="password"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormControl>
                    <FormControl isRequired mt={6}>
                      <FormLabel>Confirm password</FormLabel>
                      <Input
                        type="password"
                        placeholder="Confirm password"
                        value={confirmpassword}
                        onChange={(e) => setconfirmPassword(e.target.value)}
                        required
                      />
                    </FormControl>
                  </Flex>
                </ModalBody>
              </>
            )}
            {step === 2 && (
              <div>
                <ModalHeader>Step 2:Business Settings</ModalHeader>
                <ModalCloseButton />
                <div className="pl-4 flex flex-col space-y-10">
                  <div>
                    <FormControl>
                      <FormLabel>Point equivalence</FormLabel>
                      <Input
                        type="number"
                        value={points_equivalence}
                        onChange={(e) => setPointsEquivalence(e.target.value)}
                        required
                      />
                    </FormControl>
                    <p className="pt-3 text-gray-500">How much should one spend to earn 1 point in usd$?</p>
                  </div>
                  <div>
                    <FormControl>
                      <FormLabel>Point value</FormLabel>
                      <Input
                        type="number"
                        value={pointValue}
                        onChange={(e) => setPointValue(e.target.value)}
                        required
                      />
                    </FormControl>
                    <p className="pt-3 text-gray-500">Worth of one point in usd?</p>
                  </div>
                  <div>
                    <FormControl>
                      <FormLabel>Minimum points redeemed</FormLabel>
                      <Input
                        type="number"
                        value={minimumredeemed}
                        onChange={(e) => setMinimumRedeemed(e.target.value)}
                        required
                      />
                    </FormControl>
                    <p className="pt-3 text-gray-500">Minimum points to be redeemed is 350 points worth 2usd </p>
                  </div>
                </div>
              </div>
            )}
            <ModalFooter>
              {step > 1 && (
                <Button colorScheme="gray" mr={3} onClick={handlePrevStep}>
                  Back
                </Button>
              )}
              {step < 3 ? (
                <Button colorScheme="blue" onClick={handleNextStep}>

                  {loader ? (
                    <div className="flex flex-row gap-x-2 justify-center align-center"><h1 className="text-center">Please wait</h1>
                      <Spinner thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                      /></div>
                  ) : (
                    <div>Next</div>
                  )}
                </Button>
              ) : (
                <Button colorScheme="green" onClick={handleSubmit}>
                 {loader ? (
                    <div className="flex flex-row gap-x-2 justify-center align-center">
                     <h1 className="text-center">Please wait</h1>
                      <Spinner thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                      /></div>
                  ) : (
                    <div>Finish</div>
                  )}
                </Button>
              )}
            </ModalFooter>
          </ModalContent>
        </div>
      </Modal>
    </ChakraProvider>
  );
};

export default RegisterFormModal;
